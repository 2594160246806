import { Injectable } from '@angular/core';
import { IAddModal } from '../add-modal.interface';

@Injectable({
  providedIn: 'root',
})
export class AddModalService {
  modal: IAddModal = {} as IAddModal;

  setModal(modal: IAddModal) {
    this.modal = modal;
  }

  open() {
    if (this.modal) {
      this.modal.open();
    }
  }

  close() {
    if (this.modal) {
      this.modal.close();
    }
  }
}
