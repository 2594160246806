import { AddModalService } from '@add-modal/services';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { DataSourcesActions } from './data-sources.actions';

@Injectable()
export class DataSourcesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly addModalService: AddModalService,
  ) {}

  openSourcesAdd$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DataSourcesActions.addWizardRequested),
        tap(() => {
          this.addModalService.open();
        }),
      );
    },
    { dispatch: false },
  );
}
